import axios from "axios";
import qs from "qs";

import { Links } from "@/api/links.js";

export class EventTypes {
  checkIfEmpty(agendaId = null) {
    const params = {};
    if (agendaId) params["agendaId"] = agendaId;

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      Links.EVENT_TYPES_CHECK_IF_EMPTY() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  }
  getAll(
    searchInput = null,
    pagination = true,
    page = 1,
    order = "desc",
    itemsPerPage = 10,
    agendaId = null,
    getForMultipleServices = false,
    selectedEventTypes = []
  ) {
    const params = {};

    if (!pagination) {
      params["pagination"] = false;
    } else {
      params["page"] = page;
      params["itemsPerPage"] = itemsPerPage;
    }

    if (searchInput) params["searchInput"] = searchInput;

    if (agendaId) params["agendaId"] = agendaId;

    if (getForMultipleServices) {
      params["getForMultipleServices"] = true;
      params["selectedEventTypes"] = selectedEventTypes;
    }

    params["order"] = order;

    return axios.get(Links.EVENT_TYPES_LINK(), { params });
  }
  getPaged(
    itemsPerPage = 30,
    page = 1,
    order = "desc",
    agendaId = null,
    getForMultipleServices = false,
    selectedEventTypes = []
  ) {
    const params = {};
    params["page"] = page;
    params["itemsPerPage"] = itemsPerPage;
    params["order"] = order;
    if (agendaId) params["agendaId"] = agendaId;
    if (getForMultipleServices) {
      params["getForMultipleServices"] = true;
      params["selectedEventTypes"] = selectedEventTypes;
    }
    return axios.get(Links.EVENT_TYPES_LINK(), { params });
  }
  post(item) {
    return axios.post(Links.EVENT_TYPES_LINK(), item);
  }
  put(id, item) {
    return axios.put(Links.EVENT_TYPE_LINK(id), item);
  }
  delete(id) {
    return axios.delete(Links.EVENT_TYPE_LINK(id));
  }
  clone(id) {
    return axios.post(Links.SERVICE_CLONE(), { id });
  }
  checkAllowCreate() {
    return axios.get(Links.EVENT_TYPE_CHECK_ALLOW_CREATE_LINK());
  }
  getAvailableSpotPerOption(agendaSlug, eventTypeSlug, date) {
    const params = {
      agendaSlug,
      eventTypeSlug,
      date,
    };
    return axios.get(Links.GET_AVAILABLE_SPOTS_PER_OPTION() + "?" + qs.stringify(params));
  }
  getForPublicAgendaMultipleServicesPerEvent(agendaSlug, selectedEventTypes = []) {
    const params = {
      agendaSlug,
      selectedEventTypes: selectedEventTypes.map(eventType => eventType.slug),
    };
    return axios.get(Links.GET_FOR_PUBLIC_AGENDA_MULTIPLE_SERVICES_PER_EVENT(), { params });
  }
}

export const APIEventTypes = new EventTypes();
