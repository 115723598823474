import { defineStore } from "pinia";

import { APIEventTypes } from "@/api/event-types.js";
import { useCompaniesStore } from "@/stores/models-companies.js";
import { useEventsStore } from "@/stores/models-events";

export const useEventTypesStore = defineStore("eventTypes", {
  state: () => {
    return {
      data: [],
      activeSearchTerm: "",
      allowCreateEventType: true,
    };
  },
  getters: {
    get: state => id => state.data.find(type => type.id === id),
    getAll(state) {
      return state.data.map(item => ({ ...item }));
    },
    getNotDeleted(state) {
      return state.data
        .filter(item => {
          // TODO: https://app.clickup.com/t/2495903/FIC-729
          return !item.deleted;
        })
        .map(item => ({ ...item }));
    },
    /* REVIEW: This should be done un API not in store
     * https://app.clickup.com/t/2495903/FIC-729
     */
    getNotDeletedSorted: state => order => {
      const eventsStore = useEventsStore();
      const companiesStore = useCompaniesStore();

      function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      }

      return state.data
        .filter(service => {
          // TODO: https://app.clickup.com/t/2495903/FIC-729
          return !service.deleted;
        })
        .filter(service => {
          // stop the filter if there is only one agenda
          if (!companiesStore.hasMultipleAgenda) return true;

          // display all services if filter not set
          if (eventsStore.currentAgendaId === 0) return true;

          for (let agenda of service.agendas) {
            if (agenda.id === eventsStore.currentAgendaId) return true;
          }
          return false;
        })
        .filter(service => {
          if (state.activeSearchTerm.length === 0) return true;

          const escapedTerm = escapeRegExp(state.activeSearchTerm);
          const regex = new RegExp(escapedTerm, "i");

          return regex.test(service.name);
        })
        .sort((a, b) => {
          if (order == "desc") {
            return a.id < b.id ? 1 : -1;
          } else {
            return a.id > b.id ? 1 : -1;
          }
        })
        .map(service => ({ ...service }));
    },
  },
  actions: {
    async apiCreate(payload) {
      const res = await APIEventTypes.post(payload.eventType);
      this.data.push(res.data);
    },
    async apiUpdate(payload) {
      const res = await APIEventTypes.put(payload.id, payload.eventType);
      const index = this.data.findIndex(item => item.id == res.data.id);
      this.data[index] = res.data;
    },
    async apiRemove(payload) {
      await APIEventTypes.delete(payload.id);

      // refresh the company services after delete
      const companiesStore = useCompaniesStore();
      await companiesStore.getCompany();
    },
    async cloneService(id) {
      const service = await APIEventTypes.clone(id);
      this.data.push(service.data);
    },
    async apiCheckAllowCreate() {
      const res = await APIEventTypes.checkAllowCreate();
      if (res.data?.allowed == false) {
        this.allowCreateEventType = false;
      }
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        // Update
        this.data[index] = object;
      } else {
        // Insert
        this.data.push(object);
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") this.data.splice(index, 1);
    },
  },
});
